import { get } from 'lodash';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { UITab } from '@/types';
import { AddBotType } from '@/Rpa/constants';
import { BaseUser } from '@/types/users.types';
import {
  ActiveBot,
  BotBreadCrumb,
  BotData,
  BotList,
  BotListResponse,
  BotMeta,
  BotTree,
  NewRpaProject,
  ProjectBot,
  ProjectBotDetails,
  PromotionChecklistData,
} from '@/types/projects.types';
import { DevBotList } from '@/types/rpa-admin.types';
import { logger } from '@/utils/logger';
import {
  DocumentationSection,
  DraftListingModule
} from '@/types/listings.types';
import { ListingSectionTemplateTypes, ListingTypes } from '@/constants';
import Vue from 'vue';
import uniq from 'lodash/uniq';
import { getListingTypes, ListingTypesResponse } from '@/api/publishing.api';
import cloneDeep from 'lodash/cloneDeep';
import { processDetails } from '@/utils/listings';

function botTree(files: BotList[], botSystemMnemonic: string): BotMeta[] {
  function endRow(text: string): BotMeta {
    return {
      text,
      _isFile: false,
      children: [],
      clientLastModifiedDate: '',
      fileId: '',
      folderId: '',
      name: '',
      path: '',
      uploadedDate: '',
    };
  }

  function buildBranch(path: string[], bot: BotList, fileNode: BotMeta): BotMeta {
    let i = path.length - 1;
    let previousNode = fileNode;
    while (i >= 0) {
      const currentNode = makeNode(bot, false, path[i]);
      currentNode.children.push(previousNode);
      previousNode = currentNode;
      i--;
    }
    return previousNode;
  }

  function makeNode(bot: BotList, isBot: boolean, name?: string): BotMeta {
    if (isBot) {
      return {
        system: botSystemMnemonic, // this is intended to be overwritten if bot list service will send a system name
        ...bot,
        _isFile: isBot,
        children: [],
        text: bot.name,
      };
    } else {
      return {
        text: name || '',
        children: [],
        _isFile: isBot,
        clientLastModifiedDate: '',
        fileId: bot.fileId,
        folderId: bot.folderId,
        name: '',
        uploadedDate: '',
        path: '',
      };
    }
  }

  const result: BotMeta[] = [];

  files.forEach((bot, index) => {
    const path = bot.path.split('\\');
    path.pop();
    const fileNode = makeNode(bot, true);
    if (index === 0) {
      result.push(buildBranch(path, bot, fileNode));
    } else {
      const newPath = path;
      let currentFolder = newPath[0];
      const tree: BotMeta = result[0];
      let arr: BotMeta[] = [tree, endRow('end')];
      let parentNode: BotMeta = tree;
      while (arr.length) {
        const currentNode = arr.shift();
        if (!currentNode) {
          logger.warn('this should never happen');
          return;
        }
        if (currentNode.text === currentFolder) {
          parentNode = currentNode;
          newPath.shift();
          if (newPath.length) {
            currentFolder = newPath[0];
            arr = [...currentNode.children, endRow('end')];
          } else {
            parentNode.children.push(buildBranch(newPath, bot, fileNode));
            arr = [];
          }
        } else if (currentNode.text === 'end') {
          parentNode.children.push(buildBranch(newPath, bot, fileNode));
          arr = [];
        }
      }
    }
  });

  return result;
}

// eslint-disable-next-line no-unused-vars
const YES = 'Yes';
const NO = 'No';

function addBotToProjectTabList(): UITab[] {
  return [
    {
      component: 'SelectBot',
      disabled: false,
      key: 'select-bot',
      text: 'Select Bot',
    },
    {
      component: 'BotDetails',
      disabled: true,
      key: 'bot-details',
      text: 'Add bot details',
    },
    {
      component: 'RpaDocumentation',
      disabled: true,
      key: 'rpa-add-documentation',
      text: 'Documentation',
    },
  ];
}

function addBotToExistingBotRegistrationTabList(): UITab[] {
  return [
    {
      component: 'SelectBot',
      disabled: false,
      key: 'select-bot',
      text: 'Select Bot',
    },
  ];
}

function newRpaProjectTabList(): UITab[] {
  return [
    {
      component: 'ProjectDetailsRpa',
      disabled: false,
      key: 'project-details',
      text: 'Add project details',
    },
    {
      component: 'SelectBot',
      disabled: true,
      key: 'select-bot',
      text: 'Select Bot',
    },
    {
      component: 'BotDetails',
      disabled: true,
      key: 'bot-details',
      text: 'Add bot details',
    },
    {
      component: 'RpaDocumentation',
      disabled: true,
      key: 'rpa-add-documentation',
      text: 'Documentation',
    },
  ];
}

function initialBotDetailState(): ProjectBotDetails {
  return {
    botName: '',
    botDescription: '',
    path: '',
    fileName: '',
    systemIntegrations: [],
    businessProcess: '',
    businessUnit: '',
    deliveredFor: '',
    configurationId: '',
    configurationIdName: '',
    packageId: '',
    isOnCyberArk: false,
    snowDetails: {
      infoClassification: '',
      pType: '',
      gxp: '',
      sox: '',
    },
    botSystemMnemonic: 'V11', 
  };
}

function initialChosenBot(): BotData {
  return {
    clientLastModifiedDate: '',
    fileId: '',
    folderId: '',
    name: '',
    path: '',
    uploadedDate: '',
    _isFile: false,
    text: '',
  };
}

function initialNewProject(): NewRpaProject {
  return {
    projectName: '',
    projectDescription: '',
    projectBusinessUnit: '',
    projectBusinessSubUnit: '',
    botSystemMnemonic: 'V11',
  };
}

function initialPromotionTabList(): UITab[] {
  return [
    {
      component: 'PromotionChecklist',
      disabled: false,
      key: 'promotion-checklist',
      text: 'Complete Checklist',
    },
    {
      component: 'PromoteQaBot',
      disabled: true,
      key: 'promote-qa-bot',
      text: 'Select Dependencies',
    },
  ];
}

function initialPromotionChecklist(): PromotionChecklistData {
  return {
    jiraTicketNumber: '',
    techChampion: [],
    deploymentType: '',
    botChanges: [],
    functionalDesign: {
      ticketNumber: '',
      completed: NO,
    },
    technicalDesign: {
      ticketNumber: '',
      completed: NO,
    },
    codeReview: {
      ticketNumber: '',
      completed: NO,
    },
    unitTesting: {
      ticketNumber: '',
      completed: NO,
    },
    riskAssessment: {
      ticketNumber: '',
      completed: NO,
    },
    rampUpPlan: {
      ticketNumber: '',
      completed: NO,
    },
    targetApplications: {
      ticketNumber: '',
      completed: NO,
    },
    robotAccounts: {
      ticketNumber: '',
      completed: NO,
    },
    virtualDesktop: {
      ticketNumber: '',
      completed: NO,
    },
    businessUnitTeam: {
      ticketNumber: '',
      completed: NO,
    },
  };
}

function initialProjectBot(): ProjectBot {
  return {
    path: '',
    botName: '',
    fileName: '',
    botRegistrationId: -1,
    botDeploymentId: -1,
    riskPartner: { mudId: '', email: '' },
    businessUnit: '',
    deliveredFor: '',
    isOnCyberArk: false,
    environments: [],
    businessOwner: { mudId: '', email: '' },
    botDescription: '',
    businessProcess: '',
    configurationId: '',
    systemIntegrations: [],
    configurationIdName: '',
    promotionStatuses: [],
    listingId: -1,
    snowData: { snowDetails: {} },
    botSystemMnemonic: 'V11',
    systems: [],
  };
}

function initialRpaDraftListing(): any {
  return {
    listingName: '',
    listingDescription: '',
    listingTypeId: ListingTypes.RPA,
    extendedProperties: {
      githubRepo: null,
      image: '',
      keywords: [],
      documentationSections: [
        {
          sectionInfo: {
            sectionName: 'Overview',
            sectionTemplateType: ListingSectionTemplateTypes.Markdown,
            sectionTemplateTypeId: 1,
            sectionContent: '',
          },
          meta: {
            canDelete: false,
            canRename: true,
            canReorder: true,
            canLinkContent: false,
            canEditContent: true,
          },
        },
      ],
      details: [],
    },
    listingUsers: [],
    teams: [],
    categoryId: 0,
    businessUnitId: 0,
    isPrivate: false,
  };
}

@Module({ dynamic: true, store, name: 'projectRpaDetails', namespaced: true })
class ProjectRpaDetails extends VuexModule {
  public botDetails: ProjectBotDetails = initialBotDetailState();
  public newProject: NewRpaProject = initialNewProject();
  public rpaListingType: ListingTypesResponse[] = [];
  public rpaDraftListing: DraftListingModule = initialRpaDraftListing();
  public rpaActiveTab: UITab = {
    text: '',
    key: '',
  };
  public addBotTabList: UITab[] = [];

  public promotionTabList: UITab[] = [];

  public promotionActiveTab: UITab = {
    text: '',
    key: '',
  };

  public prodChecklist: PromotionChecklistData = initialPromotionChecklist();

  public breadCrumbRecord: BotBreadCrumb[] = [];
  public _defaultBreadCrumb: BotBreadCrumb = {
    text: 'Bots',
    key: 'Bots',
    route: '',
    children: [],
  };
  public chosenBot: BotData = initialChosenBot();

  private devBotList: BotTree[] = [];
  private devBotListTree: { [index: string]: BotTree[] } = {};
  public devBotListFull: { [index: string]: BotListResponse[] } = {};
  public devActiveBotList: ActiveBot[] = [];
  public editRpaBotData: ProjectBot = initialProjectBot();
  public editBotListingDetails: DraftListingModule = initialRpaDraftListing();

  public get getDevActiveBotList(): ActiveBot[] {
    return this.devActiveBotList;
  }

  public get initRpaDraftListing(): any {
    return initialRpaDraftListing();
  }

  public get keywords(): string[] {
    return this.rpaDraftListing.extendedProperties.keywords;
  }

  public promotingBot: ProjectBot = initialProjectBot();

  public get rpaTabList(): UITab[] {
    return this.addBotTabList;
  }

  public get botPromotionTabList(): UITab[] {
    return this.promotionTabList;
  }

  public get promotionChecklist(): PromotionChecklistData {
    return this.prodChecklist;
  }

  public get getChosenBot(): BotData {
    return this.chosenBot;
  }

  public get breadCrumb(): BotBreadCrumb[] {
    return this.breadCrumbRecord;
  }

  public get getDevBotList(): BotTree[] {
    return this.devBotList;
  }

  public get getDevBotListFull(): BotListResponse[] {
    return this.devBotListFull[this.newProject.botSystemMnemonic] || [];
  }

  public get getDevBotListTree(): BotTree[] {
    return this.devBotListTree[this.newProject.botSystemMnemonic] || [];
  }

  public get getDefaultBreadCrumb(): BotBreadCrumb {
    return this._defaultBreadCrumb;
  }

  public get getPromotingBot(): ProjectBot {
    return this.promotingBot;
  }

  public get getEditBotDetails(): ProjectBot {
    return this.editRpaBotData;
  }

  public get getEditBotListingDetails(): DraftListingModule {
    return this.editBotListingDetails;
  }

  @Mutation
  public setExtPropDetails(prop: { key: string; value: any }): void {
    const { key, value } = prop;
    const { details } = cloneDeep(this.rpaDraftListing.extendedProperties);
    this.rpaDraftListing.extendedProperties.details = details.map(d => {
      if (d.key === key) {
        if (key === 'rp' || key === 'bo') {
          d.value = value ? [value] : [];
        } else {
          d.value = value;
        }
      }
      return d;
    });
  }

  @Mutation
  public setPromotingBot(bot: ProjectBot): void {
    this.promotingBot = bot;
  }

  @Mutation
  public resetBotDetailsState() {
    this.botDetails = initialBotDetailState();
    this.newProject = initialNewProject();
    this.chosenBot = initialChosenBot();
    this.rpaDraftListing = initialRpaDraftListing();
    this.editRpaBotData = initialProjectBot();
    this.editBotListingDetails = initialRpaDraftListing();
  }

  @Mutation
  public setChosenBot(bot: BotData) {
    this.chosenBot = bot;
    this.botDetails.path = bot.path;
    this.botDetails.fileName = bot.name;
    this.botDetails.botSystemMnemonic = this.newProject.botSystemMnemonic;
  }

  @Mutation
  public setPromotionTabList(): void {
    this.promotionTabList = initialPromotionTabList();
    this.promotionActiveTab = this.promotionTabList[0];
  }

  @Mutation
  public setRpaTabList({ type, isNewBotRegistration } : { type: string, isNewBotRegistration: boolean }): void {
    if (type === AddBotType.PROJECT) {
      this.addBotTabList = newRpaProjectTabList();
    } else {
      if (isNewBotRegistration) {
        this.addBotTabList = addBotToProjectTabList();
      } else {
        this.addBotTabList = addBotToExistingBotRegistrationTabList();
      }
    }
    this.rpaActiveTab = this.addBotTabList[0];
  }

  @Mutation
  public setRpaDocumentationSection(payload: {
    documentationSection: DocumentationSection;
    index: number;
  }): void {
    Vue.set(
      this.rpaDraftListing.extendedProperties.documentationSections,
      payload.index,
      payload.documentationSection,
    );
  }

  @Mutation
  public addRpaDocumentationSection(documentationSection: DocumentationSection): void {
    this.rpaDraftListing.extendedProperties.documentationSections.push(documentationSection);
  }

  @Mutation
  public removeRpaDocumentationSection(index: number): void {
    Vue.delete(this.rpaDraftListing.extendedProperties.documentationSections, index);
  }

  @Mutation
  public moveRpaDocumentationSection(payload: { index: number; direction: 'up' | 'down' }): void {
    const { direction, index: i } = payload;
    const lastIndex = this.rpaDraftListing.extendedProperties.documentationSections.length - 1;
    // up being smaller index, but it sounded right...
    const isUp = direction === 'up';
    if ((isUp && i < 1) || (!isUp && i >= lastIndex)) {
      // out of bounds
      return;
    }
    let targetIndex = i;
    isUp ? targetIndex-- : targetIndex++;
    const toMove = this.rpaDraftListing.extendedProperties.documentationSections[payload.index];
    const swapTarget = this.rpaDraftListing.extendedProperties.documentationSections[targetIndex];
    if (toMove.meta.canReorder && swapTarget.meta.canReorder) {
      Vue.set(this.rpaDraftListing.extendedProperties.documentationSections, i, swapTarget);
      Vue.set(this.rpaDraftListing.extendedProperties.documentationSections, targetIndex, toMove);
    }
  }

  @Mutation
  public updateKeywords(payload: { keywords: string[]; isEdit: boolean }): void {
    if (payload.isEdit) {
      if (
        this.editBotListingDetails &&
        this.editBotListingDetails.extendedProperties &&
        this.editBotListingDetails.extendedProperties.keywords
      ) {
        this.editBotListingDetails.extendedProperties.keywords = uniq(payload.keywords);
      }
    } else {
      if (
        this.rpaDraftListing &&
        this.rpaDraftListing.extendedProperties &&
        this.rpaDraftListing.extendedProperties.keywords
      ) {
        this.rpaDraftListing.extendedProperties.keywords = uniq(payload.keywords);
      }
    }
  }

  @Mutation
  public updateIsPrivate(isPrivate: string): void {
    this.rpaDraftListing.isPrivate = Boolean(Number(isPrivate));
  }

  @Mutation
  public updateRpaActiveTab(tab: UITab) {
    this.rpaActiveTab = tab;
  }

  @Mutation
  public nextAddBotStep(): void {
    const i = this.addBotTabList.findIndex(
      (tab: UITab): boolean => tab.key === this.rpaActiveTab.key,
    );
    const nextIndex = i + 1;
    const lastIndex = this.addBotTabList.length - 1;
    if (i === -1 || i === lastIndex) {
      return;
    } else {
      const nextTab = this.addBotTabList[nextIndex];
      nextTab.disabled = false;
      this.rpaActiveTab = nextTab;
    }
  }

  @Mutation
  public nextPromotionStep(): void {
    const i = this.promotionTabList.findIndex(
      (tab: UITab): boolean => tab.key === this.promotionActiveTab.key,
    );
    const nextIndex = i + 1;
    const lastIndex = this.promotionTabList.length - 1;
    if (i === -1 || i === lastIndex) {
      return;
    } else {
      const nextTab = this.promotionTabList[nextIndex];
      nextTab.disabled = false;
      this.promotionActiveTab = nextTab;
    }
  }

  @Mutation
  public previousPromotionStep(): void {
    const i = this.promotionTabList.findIndex(
      (tab: UITab): boolean => tab.key === this.promotionActiveTab.key,
    );
    const nextIndex = i - 1;
    const nextTab = this.promotionTabList[nextIndex];
    nextTab.disabled = false;
    this.promotionActiveTab = nextTab;
  }

  @Mutation setPromotionActiveTab(tab: UITab): void {
    this.promotionActiveTab = tab;
  }

  @Mutation
  public setProjectName(name: string) {
    this.newProject.projectName = name;
  }

  @Mutation
  public setProjectBotSystemMnemonic(botSystemMnemonic: string) {
    this.botDetails.botSystemMnemonic = botSystemMnemonic;
    this.newProject.botSystemMnemonic = botSystemMnemonic;
  }

  @Mutation
  public setProjectDescription(desc: string) {
    this.newProject.projectDescription = desc;
  }

  @Mutation
  public setProjectBusinessUnit(bu: string) {
    this.newProject.projectBusinessUnit = bu;
  }

  @Mutation
  public setProjectBusinessSubUnit(sub: string) {
    this.newProject.projectBusinessSubUnit = sub;
  }

  @Mutation
  public setBotName(name: string) {
    this.botDetails.botName = name;
    this.rpaDraftListing.listingName = name;
  }

  @Mutation
  public setInfoClassification(info: string) {
    this.botDetails.snowDetails!.infoClassification = info;
  }

  @Mutation
  public setPType(type: string) {
    this.botDetails.snowDetails!.pType = type;
  }

  @Mutation
  public setGxp(g: string) {
    this.botDetails.snowDetails!.gxp = g;
  }

  @Mutation
  public setSox(s: string) {
    this.botDetails.snowDetails!.sox = s;
  }

  @Mutation
  public setIsOnCyberArk(isOn: boolean) {
    this.botDetails.isOnCyberArk = isOn;
  }

  @Mutation
  public updateRpaListing(payload: Partial<DraftListingModule>): void {
    this.rpaDraftListing = { ...this.rpaDraftListing, ...payload };
  }

  @Mutation
  public setBotDescription(desc: string) {
    this.botDetails.botDescription = desc;
    this.rpaDraftListing.listingDescription = desc;
  }

  @Mutation
  public setSystemIntegrations(integrations: string[]) {
    this.botDetails.systemIntegrations = integrations;
  }

  @Mutation
  public setBusinessOwner(businessOwner: BaseUser) {
    this.botDetails = {
      ...this.botDetails,
      businessOwner,
    };
  }

  @Mutation
  public setBusinessUnit(bu: string) {
    this.botDetails.businessUnit = bu;
  }

  @Mutation
  public setBusinessProcess(process: string) {
    this.botDetails.businessProcess = process;
  }

  @Mutation
  public setRiskPartner(riskPartner: BaseUser) {
    this.botDetails = {
      ...this.botDetails,
      riskPartner,
    };
  }

  @Mutation
  public setDeliveredFor(bu: string) {
    this.botDetails.deliveredFor = bu;
  }

  @Mutation
  public setConfigurationId(id: string) {
    this.botDetails.configurationId = id;
  }

  @Mutation
  public setConfigurationName(name: string) {
    this.botDetails.configurationIdName = name;
  }

  @Mutation
  public setJiraTicketNumber(ticket: string) {
    this.prodChecklist.jiraTicketNumber = ticket;
  }

  @Mutation
  public setTechChampion(champ: BaseUser[]) {
    this.prodChecklist.techChampion = champ;
  }

  @Mutation
  public setDeploymentType(type: string) {
    this.prodChecklist.deploymentType = type;
  }

  @Mutation
  public setBotChange(change: string[]) {
    this.prodChecklist.botChanges = change;
  }

  @Mutation
  public setFunctionalDesignTicketNumber(ticket: string) {
    this.prodChecklist.functionalDesign.ticketNumber = ticket;
  }

  @Mutation
  public setFunctionalDesignCompleted(checked: string) {
    this.prodChecklist.functionalDesign.completed = checked;
  }

  @Mutation
  public setTechnicalDesignTicketNumber(ticket: string) {
    this.prodChecklist.technicalDesign.ticketNumber = ticket;
  }

  @Mutation
  public setTechnicalDesignCompleted(checked: string) {
    this.prodChecklist.technicalDesign.completed = checked;
  }

  @Mutation
  public setCodeReviewTicketNumber(ticket: string) {
    this.prodChecklist.codeReview.ticketNumber = ticket;
  }

  @Mutation
  public setCodeReviewCompleted(checked: string) {
    this.prodChecklist.codeReview.completed = checked;
  }

  @Mutation
  public setUnitTestingTicketNumber(ticket: string) {
    this.prodChecklist.unitTesting.ticketNumber = ticket;
  }

  @Mutation
  public setUnitTestingCompleted(checked: string) {
    this.prodChecklist.unitTesting.completed = checked;
  }

  @Mutation
  public setRiskAssessmentTicketNumber(ticket: string) {
    this.prodChecklist.riskAssessment.ticketNumber = ticket;
  }

  @Mutation
  public setRiskAssessmentCompleted(checked: string) {
    this.prodChecklist.riskAssessment.completed = checked;
  }

  @Mutation
  public setRampUpPlanTicketNumber(ticket: string) {
    this.prodChecklist.rampUpPlan.ticketNumber = ticket;
  }

  @Mutation
  public setRampUpPlanCompleted(checked: string) {
    this.prodChecklist.rampUpPlan.completed = checked;
  }

  @Mutation
  public setTargetApplicationsTicketNumber(ticket: string) {
    this.prodChecklist.targetApplications.ticketNumber = ticket;
  }

  @Mutation
  public setTargetApplicationsCompleted(checked: string) {
    this.prodChecklist.targetApplications.completed = checked;
  }

  @Mutation
  public setRobotAccountsTicketNumber(ticket: string) {
    this.prodChecklist.robotAccounts.ticketNumber = ticket;
  }

  @Mutation
  public setRobotAccountsCompleted(checked: string) {
    this.prodChecklist.robotAccounts.completed = checked;
  }

  @Mutation
  public setVirtualDesktopTicketNumber(ticket: string) {
    this.prodChecklist.virtualDesktop.ticketNumber = ticket;
  }

  @Mutation
  public setVirtualDesktopCompleted(checked: string) {
    this.prodChecklist.virtualDesktop.completed = checked;
  }

  @Mutation
  public setBusinessUnitTeamTicketNumber(ticket: string) {
    this.prodChecklist.businessUnitTeam.ticketNumber = ticket;
  }

  @Mutation
  public setBusinessUnitTeamCompleted(checked: string) {
    this.prodChecklist.businessUnitTeam.completed = checked;
  }

  @Mutation
  public updateDevBotList(botList: BotTree[]): void {
    this.devBotList = botList;
  }

  @Mutation
  public updateActiveBotList(botList: ActiveBot[]): void {
    this.devActiveBotList = botList;
  }

  @Mutation
  public updateBreadCrumbRecordIndex(index: number): void {
    this.breadCrumbRecord.splice(index + 1);
  }

  @Mutation
  public addToBreadCrumbRecord(crumb: BotBreadCrumb): void {
    this.breadCrumbRecord.push(crumb);
  }

  @Mutation
  public updateBot(child: BotMeta[]): void {
    this.devBotList = child;
  }



  @Mutation
  public setDefaultBreadCrumb(defaultCrumb: BotBreadCrumb): void {
    console.log('Default Crumb: ', defaultCrumb);
    this.breadCrumbRecord = [defaultCrumb];
  }

  @Mutation
  public resetSelectBotStatus(): void {
    this.devBotListTree = {};
    this.devBotListFull = {};
    this.devBotList = [];
    this.devActiveBotList = [];
    this.breadCrumbRecord = [];
    this._defaultBreadCrumb = {
      text: 'Bots',
      key: 'Bots',
      route: '',
      children: [],
    };
  }

  @Mutation
  public resetProdChecklist(): void {
    this.prodChecklist = initialPromotionChecklist();
  }

  @Mutation
  public setActiveBotStatus(botList: ActiveBot[]): void {
    this.devActiveBotList = botList;
  }

  @Mutation
  public setSelectBotStatus(prop: { botList: DevBotList; botSystemMnemonic: string }): void {
    // Cache the full list for search
    this.devBotListFull[prop.botSystemMnemonic] = prop.botList.files;
    // Cache tree list to load after search
    this.devBotListTree[prop.botSystemMnemonic] = botTree(prop.botList.files, prop.botSystemMnemonic);
    // Start with tree list
    if (this.botDetails.botSystemMnemonic) {
      this.devBotList = this.devBotListTree[this.botDetails.botSystemMnemonic];
    } else {
      this.devBotList = this.devBotListTree[prop.botSystemMnemonic];
    }
    this._defaultBreadCrumb.children = this.devBotList;
    this.breadCrumbRecord = [this._defaultBreadCrumb];
  }

  @Mutation
  public setSelectedBotSystem(botSystemMnemonic: string): void {
    this.devBotList = this.devBotListTree[botSystemMnemonic];
    this._defaultBreadCrumb.children = this.devBotList;
    this.breadCrumbRecord = [this._defaultBreadCrumb];
  }

  @Mutation
  public setRpaListingTypes(rpaListingType: ListingTypesResponse[]): void {
    this.rpaListingType = rpaListingType;
    const details = cloneDeep(this.rpaDraftListing.extendedProperties.details);
    this.rpaDraftListing.extendedProperties.details = processDetails(
      details,
      rpaListingType[0].listingTemplate,
    );
  }

  @Mutation
  public setEditBotDetails(bot: ProjectBot): void {
    this.editRpaBotData = bot;
  }

  @Mutation
  public setEditBotListingDetails(listing: DraftListingModule): void {
    this.editBotListingDetails = listing;
  }

  @Mutation
  public setEditBotKeyVal(prop: { key: string; value: any }): void {
    const { key, value } = prop;
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    this.editRpaBotData[key] = value;
  }

  @Mutation
  public setEditBotListingDetailsKeyVal(prop: { key: string; value: any }): void {
    const { key, value } = prop;
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    this.editBotListingDetails[key] = value;
  }

  @Mutation
  public setEditBotListingDetailsExtPropDetails(prop: {
    key: string;
    value: string | string[] | BaseUser;
  }) {
    const { key, value } = prop;
    const details: any[] = get(this.editBotListingDetails, 'extendedProperties.details', []);
    details.forEach(d => {
      if (d.key === key) {
        if (key === 'rp' || key === 'bo') {
          d.value = value ? [value] : [];
        } else {
          d.value = value;
        }
      }
    });
  }

  @Action({ commit: 'setRpaListingTypes' })
  public async getRpaListingTypes(): Promise<ListingTypesResponse[]> {
    return (await getListingTypes()).data.filter(
      lt => lt.count > 0 && lt.listingTypeId > 0 && lt.listingTypeId === ListingTypes.RPA,
    );
  }
}

export const ProjectDetailsRpaModule = getModule(ProjectRpaDetails);
